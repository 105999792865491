<template>
    <div class="journal">
        <schedule v-model="active" @change="onScheduleChange"></schedule>
        <div>
            <van-search
                v-model="employee_name"
                class="search"
                placeholder="员工名称"
                >
            </van-search>
        </div>
        <div class="journal-list">
            <van-list
                v-model="loading"
                :finished="finished"
                :finished-text="listFinishedText"
                @load="onLoad"
                >
                <van-cell @click="toDetail(item)" is-link v-for="item in list" :key="item.employee_id">
                    <template #title>
                        <div class="journal-info">
                            <div>
                                <avatar :src="item.employee_avatar" color="#fff" :size="45"></avatar>
                            </div>
                            <div>
                                <div>{{item.employee_name}}</div>
                                <div>
                                    <van-tag round :text-color="item.is_journal?'#fff':''" :color="item.is_journal?'#00cca2':'#f4f4f4'">{{item.is_journal?'已发送':'未发送'}}</van-tag>
                                    <van-tag round color="#f4f4f4">{{item.employee_department}}</van-tag>
                                    <van-tag round :text-color="item.is_read?'#fff':''" :color="item.is_read?'#ee0a24':'#f4f4f4'">{{item.is_read?'已阅读':'未阅读'}}</van-tag>
                                </div>
                            </div>
                            
                        </div>
                    </template>
                </van-cell>
            </van-list>
            <van-empty v-if="list.length == 0" description="暂无相关数据~" />
        </div>
        <loadings :loading='searchLoading' />
        <!-- <copyright /> -->
    </div>
</template>

<script>
import { Search, Empty, Tag} from 'vant'
import Copyright from '@/components/Copyright'
import Loadings from '@/components/loading'
import Schedule from './schedule'
import Avatar from 'vue-avatar'
import dayjs from 'dayjs'

    export default {
        name:'journal',
        data() {
            return {
                loading:false,
                finished: true,
                searchLoading: false,
                employee_name: '',
                list:[],
                active: {date:dayjs().format('YYYY-MM-DD')},
                weeks: [],
                listFinishedText: '',
                pageSize: 10,
                pageCurrent: 1,
                journal_date:dayjs().format('YYYY-MM-DD')
            }
        },
        components: {
            Copyright,
            [Search.name]: Search,
            [Empty.name]: Empty,
            [Tag.name]: Tag,
            [Avatar.name]: Avatar,
            loadings:Loadings,
            schedule: Schedule,
        },
        created(){
            this.getList()
        },
        methods: {
            getList(){
                this.$api.custom_huidong_journal({ employee_name:this.employee_name,journal_date:this.journal_date})
                .then(res=>{
                    this.loading = false
                    this.searchLoading = false
                    this.list = res.data
                    // this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
                    // this.finished = true
                    // this.listFinishedText = this.list.length ? '没有更多了~' : ''
                    // this.pageCurrent += 1
                })
            },
            onLoad() {
                this.getList()
            },
            onScheduleChange(value) {
                this.journal_date = value.date
                this.searchLoading = true
                this.getList()
            },
            toDetail(item){
                if(item.is_journal){
                    item.is_read = 1
                }
                this.$router.push({path:'/campus/journal/detail',query:{employee_id:item.employee_id}})
            },
        },
        beforeRouteLeave(to, from, next) {
            if (to.path === '/campus/journal/detail') { // 去往详情页的时候需要缓存组件，其他情况下不需要缓存
                this.$store.commit('app/updateCatchComponents', ['journal'])
            } else {
                this.$store.commit('app/updateCatchComponents', [])
            }
            next()
        },
        watch: {
            employee_name() {
                this.searchLoading = true
                this.pageCurrent = 1
                this.getList()
            }
        },
    }
</script>

<style lang="less">
    .journal{
        background: #fff;
        &-info{
            display: flex;
            align-items: center;
            position: relative;
            .vue-avatar--wrapper{
                margin-right: 10px;
            }
            .crm-customer-phone{
                display: flex;
                align-items: center;
                position: absolute;
                right: 20px;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                justify-content: center;
                background: rgb(244,244,244);
            }
        }
        .journal-list{
            .van-cell{
                align-items: center;
                background-color: rgba(0,0,0,0);
            }
            .van-tag--default{
                margin-right: 5px;
                color: #888;
                font-size: 10px;
            }
        }
    }
</style>